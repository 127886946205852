<template>
  <title-link-wrapper
    :to="{ name: 'SingleCompany', params: { companyId: $route.params.companyId } }"
    :title="$t('components.addCompanyUser')"
  >
    <user-details
      :backLink="{ name: 'SingleCompany', params: { companyId: $route.params.companyId } }"
      :toName="{
        name: 'EditCompanyUser',
        params: { companyId: $route.params.companyId, userId: $route.params.userId },
      }"
    ></user-details>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import UserDetails from '../../../../components/UserDetails.vue';

export default { components: { UserDetails, TitleLinkWrapper }, name: 'SingleSysadmin' };
</script>
<style lang="scss" scoped></style>

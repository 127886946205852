<template>
  <title-wrapper :title="`${$t('components.singleCompany.title')} ${company.companyName || ''}`">
    <my-data-table
      :data="company.users"
      :headers="headers"
      :loading="loading"
      :length="length"
      :page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.firstName"
            hide-details
            :placeholder="$t('helpers.firstName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.lastName"
            hide-details
            :placeholder="$t('helpers.lastName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.email"
            hide-details
            :placeholder="$t('helpers.email')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.phone"
            hide-details
            :placeholder="$t('helpers.phoneNumber')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="slide-y-transition"
            offset-y
            :nudge-top="-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.dates"
                :label="$t('helpers.registerDate')"
                readonly
                hide-details
                :placeholder="$t('helpers.registerDate')"
                solo
                v-bind="attrs"
                v-on="on"
              >
                <template slot="append">
                  <v-icon small :class="{ active: dateMenu }">$arrowIcon</v-icon>
                </template></v-text-field
              >
            </template>
            <v-date-picker
              v-model="params.dates"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '400'"
              no-title
              scrollable
              range
              :first-day-of-week="dayOfWeek"
              :locale="selectedLanguage"
              :min="params.dates ? params.dates[0] : ''"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="1">
          <v-btn color="primary " elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>
      <template v-slot:actions="slotProps">
        <router-link
          :to="{
            name: 'SingleCompanyUser',
            params: {
              companyId: $route.params.companyId,
              userId: slotProps.item._id,
            },
          }"
        >
          <v-list-item class="hover-item-popup">
            <v-list-item-content> {{ $t('helpers.showDetails') }} </v-list-item-content>
          </v-list-item>
        </router-link>
      </template>

      <template v-slot:below
        ><v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="isSysAdmin"
          ><v-col
            ><primary-button
              :to="{
                name: 'AddCompanyUser',
                params: { companyId: $route.params.companyId },
              }"
              ><v-icon class="mr-4">mdi-plus-circle-outline</v-icon
              >{{ $t('components.singleCompany.addUser') }}</primary-button
            ></v-col
          >
        </v-row></template
      >
    </my-data-table>

    <collapse :title="$t('components.singleCompany.colapseTitle')">
      <v-row
        ><v-col class="my-0">
          <p class="mb-0">
            {{ $t('helpers.discountGroup') }}:
            {{
              company.discountGroup
                ? $t(`statics.discountGroups.${company.discountGroup}`)
                : $t('helpers.noData')
            }}
          </p></v-col
        ></v-row
      >
      <v-row class="my-0"
        ><v-col>
          <p class="mb-0">
            {{ $t('helpers.traderCode') }}: {{ company.referralCode || $t('helpers.noData') }}
          </p></v-col
        ></v-row
      >
      <v-row v-if="isDachCountry(company.countryCode)" class="my-0"
        ><v-col>
          <p class="mb-0">
            SevDesk ID: {{ company.sevdeskContactId || $t('helpers.noData') }}
          </p></v-col
        ></v-row
      >
      <v-row v-if="isDachCountry(company.countryCode)" class="my-0"
        ><v-col>
          <p class="mb-0">
            PipeDrive ID: {{ company.pipedriveOrganizationId || $t('helpers.noData') }}
          </p></v-col
        ></v-row
      >
      <v-row
        ><v-col
          >{{ company.companyName || $t('helpers.noData') }}<br />{{ $t('helpers.registerForm') }}:
          {{ company.nip || $t('helpers.noData') }}</v-col
        >
        <v-col
          >{{ company.address || $t('helpers.noData') }} <br />{{
            company.zipCode || $t('helpers.noData')
          }}
          {{ company.city || $t('helpers.noData') }}<br />{{
            getCountryName(company.countryCode) || $t('helpers.noData')
          }}</v-col
        ></v-row
      >
      <v-row class="pb-6"
        ><v-col
          >{{ $t('components.singleCompany.phoneNumber') }}:
          {{ company.phone || $t('helpers.noData') }}</v-col
        ></v-row
      >
      <v-row v-if="isSysAdmin">
        <v-col
          ><v-row
            ><primary-button class="mt-4 mr-4 mb-4" :onClick="openModal">{{
              $t('helpers.edit')
            }}</primary-button
            ><primary-button class="mt-4 mr-4 mb-4" :onClick="openresetNipModal">{{
              $t('helpers.resetNip')
            }}</primary-button>
            <primary-button
              v-if="isSynchroEnabled"
              class="mt-4 mb-4"
              :onClick="syncCompanyData"
              :loading="loadingSyncData"
              >{{ $t('helpers.syncCompanyData') }}</primary-button
            >
          </v-row></v-col
        >
        <change-address-modal
          :open="modal"
          :title="$t('components.singleCompany.modal.title')"
          :agree="$t('components.singleCompany.modal.agree')"
          :disagree="$t('components.singleCompany.modal.disagree')"
          v-on:decline="decline"
          v-on:accept="accept"
          :loading="loadingButton"
        >
          <v-form v-model="isFormValid" ref="form" class="mt-4">
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.companyName') }}
                </h2>
                <v-text-field
                  v-model="editCompany.companyName"
                  :rules="validator.requiredRules"
                  outlined
                  hide-details="auto"
                  solo
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.country') }}
                </h2>
                <v-autocomplete
                  solo
                  outlined
                  hide-details="auto"
                  v-model="editCompany.countryCode"
                  :items="mappedCountries"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.registerForm') }}
                </h2>
                <v-text-field
                  v-model="editCompany.nip"
                  :rules="foreignCountryEditView ? validator.foreignNipRules : validator.nipRules"
                  hide-details="auto"
                  outlined
                  solo
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.phoneNumber') }}
                </h2>
                <v-text-field
                  v-model="editCompany.phone"
                  :rules="validator.phoneRules"
                  outlined
                  solo
                  hide-details="auto"
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.address') }}
                </h2>
                <v-text-field
                  v-model="editCompany.address"
                  :rules="validator.requiredRules"
                  outlined
                  solo
                  hide-details="auto"
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12" sm="3">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.zipCode') }}
                </h2>
                <v-text-field
                  :rules="foreignCountryEditView ? [] : validator.zipCodeRules"
                  v-model="editCompany.zipCode"
                  outlined
                  solo
                  hide-details="auto"
                  elevation="0"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0 py-sm-2" col="12" sm="9">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.city') }}
                </h2>
                <v-text-field
                  :rules="validator.requiredRules"
                  v-model="editCompany.city"
                  hide-details="auto"
                  outlined
                  solo
                  elevation="0"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.traderCode') }}
                </h2>
                <v-text-field
                  v-model="editCompany.referralCode"
                  :rules="validator.requiredRules"
                  outlined
                  solo
                  elevation="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 py-sm-2" col="12">
                <h2 class="text font-weight-bold text-color">
                  {{ $t('helpers.discountGroup') }}
                </h2>
                <v-select
                  :items="discountGroups"
                  v-model="editCompany.discountGroup"
                  outlined
                  solo
                  hide-details="auto"
                  :rules="validator.requiredRules"
                  elevation="0"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </change-address-modal>
        <confirm-modal
          :title="$t('components.singleCompany.confirmation')"
          :open="resetNipModalIsOpen"
          :agree="$t('components.catalogListItem.modal.storage.agree')"
          @decline="closeresetNipModal"
          @accept="resetNipLink"
        >
          {{ $t('components.singleCompany.warning') }}</confirm-modal
        >
      </v-row>
    </collapse>
  </title-wrapper>
</template>
<script>
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import Collapse from '../../../../components/Collapse.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import CompanyService from '../../../../services/CompanyService';
import ChangeAddressModal from '../../../../components/modals/ChangeAddressModal.vue';
import { validators } from '../../../../validators/Validators';
import { sortHandler } from '../../../../mixins/sortHandler';
import { countriesCodeHandler } from '../../../../mixins/countriesCodeHandler';
import { formMixin } from '../../../../mixins/formMixin';
import { permissionRolesMixin } from '../../../../mixins/permissionRolesMixin';
import { dateHandler } from '../../../../mixins/dateHandler';
import ConfirmModal from '../../../../components/modals/ConfirmModal.vue';

export default {
  name: 'SingleCompany',
  components: {
    TitleWrapper,
    MyDataTable,
    Collapse,
    PrimaryButton,
    ChangeAddressModal,
    ConfirmModal,
  },
  mixins: [sortHandler, countriesCodeHandler, formMixin, permissionRolesMixin, dateHandler],
  data() {
    return {
      count: 0,
      itemsPerPage: 6,
      length: 0,
      modal: false,
      loading: false,
      isFormValid: false,
      editCompany: {},
      loadingButton: false,
      discountGroups: [],
      dateMenu: false,
      dayOfWeek: 1,
      loadingSyncData: false,
      params: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        dates: [],
        page: 1,
        limit: 6,
        sort: '',
      },
      company: {
        companyId: '',
        users: [],
      },
      resetNipModalIsOpen: false,
    };
  },
  watch: {
    'params.page': function () {
      this.fetchCompany();
    },
  },
  methods: {
    async syncCompanyData() {
      try {
        this.loadingSyncData = true;
        await CompanyService.syncCompanyData(this.company.companyId);
        await this.fetchCompany();
        this.$toasted.global.success({
          message: this.$i18n.t('components.singleCompany.sync.success'),
        });
      } catch {
        this.$toasted.global.error({
          message: this.$i18n.t('components.singleCompany.sync.error'),
        });
      } finally {
        this.loadingSyncData = false;
      }
    },
    async resetNipLink() {
      try {
        await CompanyService.resetNipLink(this.company.companyId);
        this.$toasted.global.success({
          message: this.$i18n.t('components.singleCompany.synchronizationToast.success'),
        });
      } catch {
        this.$toasted.global.error({
          message: this.$i18n.t('components.singleCompany.synchronizationToast.error'),
        });
      } finally {
        this.closeresetNipModal();
      }
    },
    closeresetNipModal() {
      this.resetNipModalIsOpen = false;
    },
    clearParams() {
      this.params = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        page: 1,
        limit: 6,
        sort: '',
        dates: [],
        dateFrom: '',
        dateTo: '',
      };
      this.fetchCompany();
    },
    async accept() {
      try {
        this.$refs.form.validate();
        if (!this.isFormValid) return;
        const response = await CompanyService.update(this.company._id, this.editCompany);
        this.company = { ...this.company, ...response.data };
        this.modal = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingButton = false;
      }
    },
    decline() {
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    openresetNipModal() {
      this.resetNipModalIsOpen = true;
    },
    async getDiscountGroups() {
      try {
        const response = await CompanyService.getDiscountGroups();
        this.discountGroups = [...response.data];
      } catch (err) {
        console.error(err);
      }
    },
    onSearch() {
      this.params.page = 1;
      this.fetchCompany();
    },
    async fetchCompany() {
      try {
        this.loading = true;
        const users = await CompanyService.getAllCompanyUsers(
          this.company.companyId,
          this.mapParams(this.params)
        );
        this.company.users = users.data.map((user) => {
          return {
            fullName: `${user.firstName} ${user.lastName}`,
            ...user,
          };
        });

        const companyDetails = await CompanyService.get(this.company.companyId);
        this.company = { ...this.company, ...companyDetails.data };
        this.length = Math.ceil(users.count / this.itemsPerPage);
        this.editCompany = {
          address: this.company.address,
          city: this.company.city,
          zipCode: this.company.zipCode,
          nip: this.company.nip,
          companyName: this.company.companyName,
          referralCode: this.company.referralCode || '',
          phone: this.company.phone || '',
          discountGroup: this.company.discountGroup || '',
          countryCode: this.company.countryCode,
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    fetchSort() {
      this.fetchCompany();
    },

    mapParams() {
      this.sortDates();

      const params = {
        dateFrom: this.params.dateFrom,
        dateTo: this.params.dateTo,
        email: this.params.email,
        firstName: this.params.firstName,
        lastName: this.params.lastName,
        phone: this.params.phone,
        page: this.params.page,
        limit: this.params.limit,
        sort: this.params.sort,
      };
      return params;
    },
  },
  computed: {
    isSynchroEnabled() {
      return (
        !(this.company.pipedriveOrganizationId && this.company.sevdeskContactId) &&
        this.company?.users?.length > 0 &&
        this.isDachCountry(this.company.countryCode)
      );
    },
    sysAdminHeaders() {
      return [
        { text: this.$i18n.t('helpers.fullName'), value: 'fullName' },
        { text: this.$i18n.t('helpers.phoneNumber'), value: 'phone' },
        { text: this.$i18n.t('helpers.email'), value: 'email' },
        { text: this.$i18n.t('helpers.userRights'), value: 'role' },
        { text: this.$i18n.t('helpers.lastActive'), value: 'lastActive' },

        { text: '', value: 'actions', sortable: false },
      ];
    },
    salesmanHeaders() {
      return [
        { text: this.$i18n.t('helpers.fullName'), value: 'fullName' },
        { text: this.$i18n.t('helpers.phoneNumber'), value: 'phone' },
        { text: this.$i18n.t('helpers.email'), value: 'email' },
        { text: this.$i18n.t('helpers.userRights'), value: 'role' },
      ];
    },
    headers() {
      return this.isSysAdmin ? this.sysAdminHeaders : this.salesmanHeaders;
    },
    validator() {
      return validators;
    },
  },
  async created() {
    this.company.companyId = this.$route.params.companyId;
    await this.getDiscountGroups();
    await this.fetchCompany();
  },
};
</script>
<style lang="scss" scoped></style>

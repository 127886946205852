var render = function render(){var _vm=this,_c=_vm._self._c;return _c('title-link-wrapper',{attrs:{"to":{ name: 'SingleCompany', params: { companyId: _vm.$route.params.companyId } },"title":_vm.$t('components.addCompanyUser')}},[_c('edit-user',{attrs:{"backLink":{ name: 'SingleCompany', params: { companyId: _vm.$route.params.companyId } },"toDetails":{
        name: 'SingleCompanyUser',
        params: { companyId: _vm.$route.params.companyId, userId: _vm.$route.params.userId },
      },"toManage":{
        name: 'SingleCompany',
        params: { companyId: _vm.$route.params.companyId },
      },"userRoles":_vm.userRoles}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
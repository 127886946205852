<template>
  <title-link-wrapper
    :to="{ name: 'SingleCompany', params: { companyId: $route.params.companyId } }"
    :title="$t('components.addCompanyUser')"
  >
    <edit-user
      :backLink="{ name: 'SingleCompany', params: { companyId: $route.params.companyId } }"
      :toDetails="{
        name: 'SingleCompanyUser',
        params: { companyId: $route.params.companyId, userId: $route.params.userId },
      }"
      :toManage="{
        name: 'SingleCompany',
        params: { companyId: $route.params.companyId },
      }"
      :userRoles="userRoles"
    ></edit-user>
  </title-link-wrapper>
</template>
<script>
import EditUser from '../../../../components/EditUser.vue';
import TitleLinkWrapper from '../../../../components/TitleLinkWrapper.vue';
import { roles } from '../../../../static/roles';

export default {
  components: { TitleLinkWrapper, EditUser },
  name: 'EditCompanyUser',
  computed: {
    userRoles() {
      return [roles.EMPLOYEE, roles.COMPANY_ADMIN];
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <v-expansion-panels class="my-4">
    <v-expansion-panel @click="toggleCollapse">
      <v-expansion-panel-header expand-icon="mdi-menu-down">
        {{ title }}
        <template v-slot:actions>
          <v-icon :color="active ? 'primary' : ''"> $expand </v-icon>
        </template></v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-divider class="mb-4"></v-divider>
        <div ref="content" :class="active ? 'panel-bottom-content-active' : ''"></div>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'Collapse',
  props: ['title', 'color'],
  data() {
    return {
      collapseHash: '',
      active: false,
    };
  },
  methods: {
    goToCurrentCollapse() {
      this.$vuetify.goTo(this.$refs.content, {
        offset: 100,
      });
    },

    toggleCollapse() {
      this.active = !this.active;
      if (this.active) {
        setTimeout(() => {
          this.goToCurrentCollapse();
        }, 250);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
